html {
	line-height: 1.15;
}

body {
    font-family: 'Roboto', sans-serif;
    margin: 0;
    padding: 0 !important;
    overflow: overlay !important;
}

.css-1u2w381-MuiModal-root-MuiDrawer-root {
	z-index: 1000 !important;
}

/* accordion styling */
.css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root {
    min-height: 10px !important;
    padding: 25px 5% !important;
}

.css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root {
    border-bottom: 1px solid white !important
}

.css-1elwnq4-MuiPaper-root-MuiAccordion-root {
    box-shadow: none !important
}

.css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded {
    border-bottom: 1px solid white !important
}

.css-o4b71y-MuiAccordionSummary-content {
    margin: 0px !important;
}

.css-15v22id-MuiAccordionDetails-root {
    padding: 20px 8% 10px !important
}

.css-1elwnq4-MuiPaper-root-MuiAccordion-root:before {
    background-color: transparent !important
}

.css-67l5gl::before {
	display: none !important;
	background-color: transparent !important
}

/* carousel styling */
.carousel-header {
	position: relative;
	background-color: transparent;
	height: 100vh !important;
	height: calc(var(--vh, 1vh) * 100) !important;
	width: 100%;
	overflow: hidden;
}

.carousel {
	background: transparent;
	height: 100vh !important;
	height: calc(var(--vh, 1vh) * 100) !important;
	width: 100vw;
}

.carousel-cell {
	display: block;
	width: 100vw;
	height: 100% !important;
	display: -webkit-box;
	display: -webkit-flex;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	justify-content: center;
	-webkit-align-items: center;
	align-items: center;
	transition: 0.1s ease-in-out;
}

#full_win_size {
	position: relative;
	display: block;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-size: cover;
	background-position: center center;
	-webkit-transform: matrix(1,0,0,1,0,0);
	transform: matrix(1,0,0,1,0,0);
	will-change: transform;
    transform-style: preserve-3d;
    perspective: 98px;
	transform: translate(0%, 0px);
}

.carousel-video {
	position: relative;
	top: 50%;
	left: 49.9%;
	min-width: 177.77vh;
	min-height: 100%;
	width: 100vw;
	height: auto;
	z-index: 1;
	transform: translate3d(-50%,-49.9%,0);
	background-size: cover;
}

.flickity-viewport {
	height: 100vh !important;
	height: calc(var(--vh, 1vh) * 100) !important;
}

.flickity-slider {
	height: 100vh !important;
	height: calc(var(--vh, 1vh) * 100) !important;
}

.flickity-prev-next-button {
	visibility: hidden !important;
}

.flickity-page-dots {
	visibility: hidden !important;
}

.carousel-info {
	left: 10%;
	right: 10%;
	margin-top: 4% !important;
	display: flex;
	position: absolute;
	flex-direction: column;
	z-index: 1;
	color: white;
	bottom: 30%;
	max-width: 900px;
	margin: 0 auto;
	text-decoration: none;
	font-weight: 300;
	font-family: 'Proxima Nova' ,sans-serif;
	margin-bottom: 15px !important;
}

.carousel-info h1 {
	margin: 0
}

.carousel-info-link span {
	margin-bottom: 15px !important;
	max-width: 640px;
	line-height: 34px;
	vertical-align: baseline;
	font-size: 20px;
	font-weight: 300;
}

.carousel-info-heading {
	font-weight: 300;
	font-family: 'Proxima Nova' ,sans-serif;
	margin: 0;
	color: white;
	padding: 0;
	letter-spacing: normal;
	word-spacing: 0;
	vertical-align: baseline;
	font-size: 40px;
}

.un {
	display: inline-block;
}
  
.un::after {
	content: '';
	width: 0px;
	height: 1px;
	display: block;
	background: white;
	transition: 500ms;
}

.carousel-info:hover .un::after {
	width: 100%;
}

.carousel-info-link-arrow {
	width: 30px;
	transform: .4s ease-out;
	-webkit-transform: .4s ease-out;
	transition: .4s ease-out;
	margin-left: 10px;
}

.carousel-info:hover .carousel-info-link-arrow {
	transform: translateX(11px);
}

/* translation page styling */
#transSourceInput {
	color: white;
}

#transTargetInput {
	color: white;
}

#transSourceInput-label {
	color: white;
}

#transTargetInput-label {
	color: white;
}

textarea {
	padding: 20px 58px 20px 24px;
}

textarea:focus {
	outline: none;
	padding: 20px 58px 20px 24px;
}

/* add space top to hide content behind navbar */
.top-spacer {
    height: 80px;
    background-color: white;
    position: inherit;
    top: 0;
    z-index: 100 !important;
    background-color: #282e45;
}

.top-spacer::marker {
	color: transparent;
}