.chatbot-container {
    height: 100vh;
    width: 100vw;
    background-color: #282e45;
    border-bottom: 1px solid white;
    align-items: center;
    justify-content: center;
    display: flex;
}

.navbar {
    padding: 0 !important;
}

.chat {
    margin-top: auto;
    margin-bottom: auto;
}
.card {
    height: 80vh !important;
    border-radius: 15px !important;
    background-color: rgba(0,0,0,0.4) !important;
    margin-top: 80px;
}
.contacts_body{
    padding:  0.75rem 0 !important;
    overflow-y: auto;
    white-space: nowrap;
}
.msg_card_body{
    height: 60vh;
    overflow-y: auto;
}
.card-header{
    border-radius: 15px 15px 0 0 !important;
    border-bottom: 0 !important;
}
.card-footer{
    border-radius: 0 0 15px 15px !important;
    border-top: 0 !important;
    bottom: 0 !important;
    position: absolute !important;
    width: 90% !important;
}

.container{
    align-content: center;
}
.search{
    border-radius: 15px 0 0 15px !important;
    background-color: rgba(0,0,0,0.3) !important;
    border:0 !important;
    color:white !important;
}
.search:focus{
     box-shadow:none !important;
   outline:0px !important;
}
.type_msg{
    background-color: rgba(0,0,0,0.3) !important;
    border:0 !important;
    color:white !important;
    height: 60px !important;
    overflow-y: auto;
}
    .type_msg:focus{
     box-shadow:none !important;
   outline:0px !important;
}
.attach_btn{
border-radius: 15px 0 0 15px !important;
background-color: rgba(0,0,0,0.3) !important;
    border:0 !important;
    color: white !important;
    cursor: pointer;
}
.send_btn{
border-radius: 0 15px 15px 0 !important;
background-color: transparent;
    border:0 !important;
    color: white !important;
    cursor: pointer;
}
.search_btn{
    border-radius: 0 15px 15px 0 !important;
    background-color: rgba(0,0,0,0.3) !important;
    border:0 !important;
    color: white !important;
    cursor: pointer;
}
.contacts{
    list-style: none;
    padding: 0;
}
.contacts li{
    width: 100% !important;
    padding: 5px 10px;
    margin-bottom: 15px !important;
}
.active{
    background-color: rgba(0,0,0,0.3);
}
.user_img{
    height: 70px;
    width: 70px;
    border:1.5px solid #f5f6fa;

}
.user_img_msg{
    height: 40px;
    width: 40px;
    border:2px solid #f5f6fa;
}
.img_cont{
    position: relative;
    height: 70px;
    width: 70px;
}
.img_cont_msg{
    height: 40px;
    width: 40px;
}

.send-icon {
    height: 20px;
    width: 20px;
}

.online_icon{
position: absolute;
height: 15px;
width:15px;
background-color: #4cd137;
border-radius: 50%;
bottom: 0.2em;
right: 0.4em;
border:1.5px solid white;
}
.offline{
background-color: #c23616 !important;
}
.user_info{
margin-top: auto;
margin-bottom: auto;
margin-left: 15px;
}
.user_info span{
font-size: 20px;
color: white;
}
.user_info p{
font-size: 10px;
color: rgba(255,255,255,0.6);
}
.video_cam{
margin-left: 50px;
margin-top: 5px;
}
.video_cam span{
color: white;
font-size: 20px;
cursor: pointer;
margin-right: 20px;
}
.msg_cotainer{
margin-top: auto;
margin-bottom: auto;
margin-left: 10px;
border-radius: 25px;
background-color: #82ccdd;
padding: 10px;
position: relative;
}
.msg_cotainer_send{
margin-top: auto;
margin-bottom: auto;
margin-right: 10px;
border-radius: 25px;
background-color: #78e08f;
padding: 10px;
position: relative;
}
.msg_time{
position: absolute;
left: 0;
bottom: -15px;
color: rgba(255,255,255,0.5);
font-size: 10px;
}
.msg_time_send{
position: absolute;
right:0;
bottom: -15px;
color: rgba(255,255,255,0.5);
font-size: 10px;
}
.msg_head{
position: relative;
}
#action_menu_btn{
position: absolute;
right: 10px;
top: 10px;
color: white;
cursor: pointer;
font-size: 20px;
}
.action_menu{
z-index: 1;
position: absolute;
padding: 15px 0;
background-color: rgba(0,0,0,0.5);
color: white;
border-radius: 15px;
top: 30px;
right: 15px;
display: none;
}
.action_menu ul{
list-style: none;
padding: 0;
margin: 0;
}
.action_menu ul li{
    width: 100%;
    padding: 10px 15px;
    margin-bottom: 5px;
}
.action_menu ul li i{
padding-right: 10px;

}
.action_menu ul li:hover{
    cursor: pointer;
    background-color: rgba(0,0,0,0.2);
}
@media(max-width: 576px){
.contacts_card{
margin-bottom: 15px !important;
}
}

body::-webkit-scrollbar {
    width: 5px;
}
body::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.1);
}
body::-webkit-scrollbar-thumb {
    background-image: linear-gradient(45deg, #00aeff, #a68eff);
    border-radius: 10px;
    -webkit-box-shadow: rgba(0,0,0,.12) 0 3px 13px 1px;
}

textarea::-webkit-scrollbar {
    width: 5px;
}
textarea::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.1);
}
textarea::-webkit-scrollbar-thumb {
    background-image: linear-gradient(45deg, #00aeff, #a68eff);
    border-radius: 10px;
    -webkit-box-shadow: rgba(0,0,0,.12) 0 3px 13px 1px;
}

.msg_card_body::-webkit-scrollbar {
    width: 10px;
}
.msg_card_body::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.1);
}
.msg_card_body::-webkit-scrollbar-thumb {
    background-image: linear-gradient(45deg, #00aeff, #a68eff);
    border-radius: 10px;
    -webkit-box-shadow: rgba(0,0,0,.12) 0 3px 13px 1px;
}

.input-group-text {
    background-color: transparent !important;
}

.chatbot-submit-btn {
    background-color: rgba(0,0,0,0.3) !important;
    border: 0px solid transparent;
    border-left: 1px solid white;
    border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem;
}
