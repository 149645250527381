@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@300;400;500;600;700&display=swap");

*,
*:before,
*:after {
	box-sizing: border-box;
}

:root {
	--c-blue-900: #282e45;
	--c-blue-800: #111b40;
	--c-blue-700: #152148;
	--c-blue-500: #1d7bdb;
	--c-blue-300: #293359;
	--c-blue-200: #4e5985;
	--c-blue-100: #dfe8ff;
	--c-green-400: #4ade80;
	--c-purple: #743ad5;
	--c-pink: #d53a9d;
}

/* Some basic CSS overrides */
body {
	font-family: "Outfit", sans-serif;
	background-color: var(--c-blue-900);
	color: var(--c-blue-100);
}

a {
	color: inherit;
}
/* End basic CSS override */

.price-table {
	background-color: var(--c-blue-700);
	border-radius: 16px;
	max-width: 375px;
	padding: 32px;
	display: flex;
	flex-direction: column;
	position: relative;
	background-image: linear-gradient(
		135deg,
		rgba(#d53a9d, 0.15),
		rgba(#743ad5, 0.1) 20%,
		var(--c-blue-700) 40%,
		var(--c-blue-700) 100%
	);
	&:after {
		content: "";
		display: block;
		top: -3px;
		left: -3px;
		bottom: -3px;
		right: -3px;
		z-index: -1;
		position: absolute;
		border-radius: 16px;
		background-image: linear-gradient(
			135deg,
			var(--c-pink),
			var(--c-purple) 40%,
			var(--c-blue-300) 60%,
			var(--c-blue-700) 100%
		);
	}

	.price {
		font-size: 3rem;
		line-height: 1;
		font-weight: 700;
		display: inline-flex;
		align-self: center;
		align-items: center;
		gap: 4px;
		position: relative;
		color: #fff;
		small {
			font-size: 1.25rem;
			font-weight: 400;
			position: absolute;
			left: -1.5ch;
		}
	}

	.title {
		font-size: 1.25rem;
		font-weight: 600;
		line-height: 1.25;
		text-align: center;
		margin-top: 16px;
		color: #fff;
	}

	.description {
		font-size: 1rem;
		text-align: center;
		margin-top: 4px;
	}
}

.features {
	margin-top: 32px;
}

.feature {
	position: relative;
	& + & {
		margin-top: 16px;
	}
	summary {
		display: inline-flex;
		align-items: center;
		list-style: none; // Remove arrow
		&::-webkit-details-marker {
			display: none; // Remove arrow
		}
	}

	&[open] summary:after {
		content: "";
		display: block;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		position: fixed;
		z-index: 50;
	}

	&[open] {
		div {
			animation: scale 0.15s ease;
		}
	}

	.checkmark {
		width: 24px;
		height: 24px;
		border-radius: 50%;
		background-color: var(--c-green-400);
		display: flex;
		align-items: center;
		justify-content: center;
		margin-right: 12px;
		svg {
			width: 18px;
			height: 18px;
			color: #fff;
		}
	}

	.question-icon {
		display: flex;
		align-items: center;
		margin-left: 6px;
		cursor: pointer;
	}

	.answer {
		padding: 12px;
		background-color: var(--c-blue-800);
		border-radius: 6px;
		position: absolute;
		top: -12px;
		z-index: 100;
		transform: translatey(-100%) translatex(-50%);
		transform-origin: bottom center;
		width: 80%;
		left: 50%;
		border: 1px solid var(--c-blue-300);
		box-shadow: 0 15px 30px 0 rgba(#000, 0.1);
	}
}
