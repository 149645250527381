h1 {
    text-align: center;
    margin-top: 100px;
}

.scrappingContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 2rem;
    font-family: 'Josefin Sans';
}

.infoCard {
    min-width: 300px;
    min-height: 300px;
    border: 1px solid white;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.infoCardHeading {
    font-size: 30px;
    font-weight: bold;
    font-family: 'Josefin Sans';
}

.infoCardDescription {
    margin-top: 50px;
    font-size: 20px;
    font-family: 'Josefin Sans';
}

.userInfoDataTableContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.userInfoDataTable {
    width: 50%;
    background-color: white;
    color: black;
    font-family: 'Josefin Sans';
    margin-bottom: 100px;
}

.userInfoDataTable > h1 {
    margin: 0px 0px 50px 0px;
}

.instascrappingDatatableDisplay {
    width: 90%;
}

@media all and (min-width: 200px) {
    .userInfoDataTable {
        width: 100%;
    }
}

@media all and (min-width: 1440px) {
    .userInfoDataTable {
        width: 80%;
    }
}

@media all and (min-width: 1024px) {
    .userInfoDataTable {
        width: 80%;
    }
}

@media all and (min-width: 768px) {
    .userInfoDataTable {
        width: 90%;
    }
}